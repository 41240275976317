@keyframes exitedArrow {
  from {
    transform: translateX(-5px); }
  to {
    transform: translateX(2px); } }
.arrow {
  animation: exitedArrow;
  animation-duration: 0.75s;
  /* or: Xms */
  animation-iteration-count: infinite;
  animation-direction: alternate;
  /* or: normal */
  animation-timing-function: ease;
  /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: none;
  /* or: forwards backwards, both, none */ }

/*# sourceMappingURL=style.css.map */
